<template>
  <div class="signup-group" v-if="!isProcessing">
    <div class="signup-group__complete">
      <h2 class="signup-group__complete__title">もう少しで完了です！</h2>
      <p class="signup-group__complete__text">
        アカウントが作成されました！<br>
        {{ this.createJoinedDescription() }}</p>
      <template v-if="this.isDiscordLinkage">
        <a class="signup-group__complete__link" :href="this.createOAuthURL()">Discordサーバーへ参加する</a>
      </template>
      <template v-else>
        <p class="signup-group__complete__text--warning">承認まで3営業日ほどお待ちください。</p>
        <a class="signup-group__complete__link" target="_blank" rel="noopener noreferrer" :href="this.groupURL" @click="$router.push({ name: 'signup_done' })">グループへの参加申請</a>
      </template>
      <div class="signup-group__warning" v-if="this.isDiscordLinkage">
        <p class="signup-group__warning__text">
          ※ {{ serviceName }}では、会員限定のグループにコミュニケーションツールである
          <a class="signup-group__warning__text__link"
             href="https://discord.com/" target="_blank" rel="noopener noreferrer">Discord</a>を使用しております。
        </p>
        <p class="signup-group__warning__text">
          ※ 本システムは
          <a class="signup-group__warning__text__link"
             href="https://nukumo.link" target="_blank" rel="noopener noreferrer">一般社団法人nukumo</a>が運営する月額課金サービス「サブスキュー」を利用しています。そのため、Discordサーバーへ参加する際、サブスキューより認証が求められます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import discord from '@/assets/lib/discord'

export default {
  name: 'signup_group',
  mixins: [discord],
  async mounted () {
    const promises = []

    // 限定グループ名が未取得なら取得
    if (!this.groupName) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupName'))

    // 限定グループURLが未取得なら取得
    if (!this.groupURL) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupURL'))

    // Discord連携設定していない判定のときは最新の情報を取得する
    if (!this.isDiscordLinkage) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'discordServerID'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} サービス名
     */
    serviceName () {
      return this.$store.getters['settingGeneral/settingGeneral']('serviceName').value
    },
    /**
     * @return {String} 限定グループの名前
     */
    groupName () {
      return this.$store.getters['settingGroup/groupName']
    },
    /**
     * @return {String} 限定グループのURL
     */
    groupURL () {
      return this.$store.getters['settingGroup/groupURL']
    },
    /**
     * @return {Boolean} Discord連携しているかどうか
     */
    isDiscordLinkage () {
      return this.$store.getters['settingGroup/discordLinkage']
    }
  },
  methods: {
    /**
     * 限定グループ参加時の文言を作成する
     * @return {String} 限定グループ参加時の文言
     */
    createJoinedDescription () {
      return this.isDiscordLinkage ?
        `限定グループ「${this.groupName}」（Discordサーバー）に参加しましょう。` :
        `限定グループ「${this.groupName}」への参加申請をしてください。`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.signup-group {
  display: flex;
  align-items: center;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  text-align: center;
  background-color: $green_color;
  &__complete {
    width: 100%;
    padding: $padding_height $padding_width;
    &__title {
      font-size: 3.2rem;
      font-weight: bold;
      color: $white_color;
    }
    &__text {
      margin: 100px 0 0;
      font-size: 1.4rem;
      font-weight: normal;
      color: $white_color;
      text-align: center;
      word-wrap: break-word;
      &--warning {
        @extend .signup-group__complete__text;
        margin-top: 80px;
      }
    }
    &__link {
      display: inline-block;
      width: auto;
      height: auto;
      padding: 15px 20px;
      margin: 30px auto 0;
      font-size: 1.2rem;
      font-weight: bold;
      color: $green_color !important;
      background-color: $white_color;
      border-radius: 15px;
    }
  }
  &__warning {
    margin-top: $unit_size * 3;
    color: $white_color;
    text-align: left;
    &__text {
      margin-bottom: $unit_size;
      &__link {
        color: $concept_color !important;
        text-decoration: underline;
      }
    }
  }
}
</style>
